import React from "react"
import * as S from "./styles"

const PrivacyPolicyPage = ({children}) => {
  return (
    <div css={S.PrivacyPolicyPageStyle}>{children}</div>
  )
}

export default PrivacyPolicyPage
