import DefaultLayout from "../../layout/DefaultLayout"
import PrivacyPolicyPage from "@mkt/ui/templates/PrivacyPolicyPage"
import useStoryblok from "@mkt/utils/storyblok/useStoryblok"
import { render } from "storyblok-rich-text-react-renderer"

const PrivacyPolicy = ({ pageContext, location }) => {
  const data = useStoryblok(pageContext.content)
  const content = data?.content || pageContext.content

  return (
    <DefaultLayout pageContext={pageContext} location={location}>
      <PrivacyPolicyPage>
        <div className="container">
          <h1>{content?.title}</h1>
          <div className="institutional">
            {render(content.text_context, {
              blokResolvers: {
                component_table: (props) => {
                  const data = props.table
                  const theadData = data.thead
                  const tbodyData = data.tbody

                  return (
                    data && (
                      <table className="table">
                        <thead>
                          <tr>
                            {Object.values(theadData).map((item, i) => (
                              <th key={i}>{item.value}</th>
                            ))}
                          </tr>
                        </thead>
                        <tbody>
                          {Object.values(tbodyData).map((tr, i) => (
                            <tr key={i}>
                              {Object.values(tr.body).map((td, i) => (
                                <td key={i}>{td.value}</td>
                              ))}
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    )
                  )
                },
              },
            })}
          </div>
        </div>
      </PrivacyPolicyPage>
    </DefaultLayout>
  )
}

export default PrivacyPolicy
